<template lang="html">
  <div class="detail" v-if="detail">
    <div class="nav">
      <div class="left">
          <img v-if="act == 0" src="../../assets/image/left.png" alt="">
            <img v-else @click="pre"   class="leftimg" src="../../assets/image/right.png" alt="">
      </div>
      <div class="no">{{detail[act].contract_no}}</div>
       <div class="right">
          <img v-if="act >= detail.length - 1" class="rightimg" src="../../assets/image/left.png" alt="">
          <img @click="next" v-else  src="../../assets/image/right.png" alt="">
      </div>
    </div>
    <van-swipe class="my-swipe" :loop="false" :show-indicators="false" ref="swiper"   @change="swipchange">
  <van-swipe-item v-for="(item, index) in detail" :key="index" >
         <div class="box" >
      <div class="list">
        <div class="left">
          合同签订时间
        </div>
        <div class="right">
          <span class="name">{{item.contract_start}}</span>
        </div>
      </div>
      <div class="list">
        <div class="left">
          合同截至时间
        </div>
        <div class="right">
          <span class="name">{{item.contract_end}}</span>
        </div>
      </div>
      <div class="list new" v-if="item.merchants_stall_list">
        <div class="left">
          商铺号
        </div>
        <div class="right">
          <div class="selectText" v-for="(items, index) in item.merchants_stall_list" :key="index">{{items.merchants_stall_number}}</div>
        </div>
      </div>
      <div class="list" @click="renew(item)">
        <div class="left">
          线上续签合同
        </div>
        <div class="right">
          查看
        </div>
      </div>
      <div class="list" @click="gorenew">
        <div class="left">
          线下续签合同
        </div>
        <div class="right">
          查看
        </div>
      </div>
    </div>
  </van-swipe-item>
</van-swipe>

  </div>
</template>

<script>
export default {
  name: 'detail',
  data() {
    return {
      act: 0,
      detail: null,
      contract_no: ''
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$require.HttpPost('/h5/contract/contractNo').then(res => {
        if (res.data) {
          this.detail = res.data;
        } else {
           this.$toast('没有合同信息');
           setTimeout(() => {
             this.$router.back();
           }, 2000);
        }
      });
    },
    renew(item) {
      if (item.contract_renewal == 0 || item.contract_renewal == 11) { //合同状态未发生变化，用户自主发起合同线上续签
        if (item.remaining_day > 30) {
          this.$toast('距离合同到期时间大于30天,无法发送续签');
        } else {
          this.$dialog.confirm({
            title: '线上续签申请',
            message: '商户您好！请确认是否申请线上续签合同，管理员看到您的申请后会对您的申请进行审批，申请确认之后管理员会发送线上合同给您确认合同信息。请您注意接收消息通知，合同信息确认无误即可进行线上签字。',
            confirmButtonColor: '#85C226',
            confirmButtonText: '确认申请'
          }).then(() => {
            this.$require.HttpPost('/h5/contract/changeContract', { contract_id: item.contract_id }).then(() => {
              this.$toast('申请已提交，等待审核');
              this.getDetail();
            });
          });
        }
      } else if (item.contract_renewal == 20) { // 用户已发起线上续签申请，此时用户可以撤回
        this.$dialog.confirm({
          title: '',
          message: '是否撤销申请',
          confirmButtonColor: '#85C226',
          confirmButtonText: '撤回'
        }).then(() => {
          this.$require.HttpPost('/h5/contract/restoreContract', { contract_id: item.contract_id }).then(() => {
            this.$toast('已撤回申请');
            this.getDetail();
          });
        });
      } else if (item.contract_renewal == 21) { // 平台已解决线上续签申请
        this.$dialog.confirm({
          title: '',
          message: '平台已拒绝您的申请，如需在此申请，请联系工作人员',
          confirmButtonColor: '#85C226',
          confirmButtonText: '拨打电话'
        }).then(() => {
          this.$common.callPhone(item.market_mobile);
        });
      } else if (item.contract_renewal == 10) { // 平台发送线上续签通知
        this.$router.push({
          path: '/contract/renewal',
          query: {
            id: item.contract_id
          }
        });
      } else if (item.contract_renewal == 30) { // 已完成线上续签
        this.$toast('你已完成续签');
      }
    },
    gorenew() {
       if (this.detail.remaining_day > 30) {
          this.$toast('距离合同到期时间大于30天,无法发送续签!');
        } else {
          this.$router.push({ path: '/contract/offlineAddress' });
        }
    },
    swipchange(e) {
      this.act = e;
      console.log(e);
    },
    pre() {
      if (this.act > 0) {
        this.act--;
      }
       this.$refs.swiper.next();
    },
    next() {
      if (this.act < this.detail.length - 1) {
        this.act++;
      }
      this.$refs.swiper.next();
    }
  }
};
</script>

<style lang="scss" scoped>
.detail {
    padding: 50px 24px;
    height: 100vh;
     box-sizing: border-box;
     .nav{
       width: 100%;
       font-size: 28px;
       display: flex;
       align-items: center;
       height: 108px;
      background: #FFFFFF;
      box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .no{
        font-size: 32rpx;
        font-family: PingFang SC;
        font-weight: bold;
        color: #85C226;
      }
       .left{
           .leftimg{
             transform: rotate(180deg);
           }
       }
       .right {
         .rightimg{
           transform: rotate(180deg);
         }
       }
       img{
         width: 38px;
         height: 38px;
       }
     }
    .my-swipe{
      width: 100%;
      height: 90%;
      box-sizing: border-box;
    }
    .box {
        box-shadow: 0 0 25px 0 rgba(57, 57, 57, 0.06);
        border-radius: 30px;
        margin-top: 30px;
        padding: 10px 20px 0;
        box-sizing: border-box;
        .list {
            height: 106px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 28px;
            border-bottom: 2px solid #EFEFEF;
            &:last-child {
                border-bottom: 0;
            }
            .left {
                color: #000000;
            }
            .right {
                .name {
                    color: #333333;
                }
                .selectText {
                    color: #85C226;
                }
            }
        }
        .new{
          height: 100%;
          padding: 10px 0;
          // .right{
          //   height: 30px;
          // }
        }
    }
}
</style>
